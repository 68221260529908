import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styles';

export const enum ScreenSize{
  Desktop,
  Laptop,
  Tablet,
  Mobile,
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.Laptop);
  const theme = useTheme();

  const getScreenSize = useCallback(() => {
    if (window.innerWidth > theme.breakpoints.laptop) {
      setScreenSize(ScreenSize.Desktop);
    }
    else if (window.innerWidth > theme.breakpoints.tablet || window.innerWidth === theme.breakpoints.laptop) {
      setScreenSize(ScreenSize.Laptop);
    }
    else if (window.innerWidth > theme.breakpoints.mobile || window.innerWidth === theme.breakpoints.tablet) {
      setScreenSize(ScreenSize.Tablet);
    }
    else {
      setScreenSize(ScreenSize.Mobile);
    }
  }, [theme]);

  useEffect(() => {
    getScreenSize();
    window.addEventListener('resize', getScreenSize);

    return () => {
      window.removeEventListener('resize', getScreenSize);
    };
  }, [getScreenSize]);

  return {screenSize};

};