/* eslint-disable no-nested-ternary */
import { FunctionComponent, ReactNode } from 'react';
import { Menu } from 'antd';
import { Icon, Stack } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { useSidebar, SidebarItem } from './useSidebar';
import './Sidebar.css';
import {
  ScreenSize,
  useScreenSize,
} from 'components/shared/layout/useScreenSize';

interface SidebarProps {
  loading?: boolean;
  menuItems?: MenuItem[];
}
interface MenuItem {
  groupKey?: string;
  groupTitle?: string;
  groupIcon?: ReactNode;
  items?: SidebarItem[];
  subMenu?: MenuItem[];
}

export const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  const { screenSize } = useScreenSize();

  if (screenSize === ScreenSize.Laptop || screenSize === ScreenSize.Desktop) {
    return <VerticalSidebar {...props} />;
  } else if (screenSize === ScreenSize.Tablet) {
    return <HorizontalSidebar {...props} />;
  } else {
    return <MobileSidebar {...props} />;
  }
};

const renderSubMenu = (
  menuItem: MenuItem,
  isSidebarItemVisible?: (item: SidebarItem) => boolean,
  getItemKey?: (item: SidebarItem) => string
) => {
  if (!menuItem.subMenu || menuItem.subMenu.length === 0) {
    return null;
  }

  if (
    isSidebarItemVisible
    && menuItem.subMenu
      .map((item) => item.items)
      .flat()
      .every((item) => !isSidebarItemVisible(item!))
  ) {
    return null;
  }

  return (
    <Menu.SubMenu
      key={menuItem.groupKey}
      icon={menuItem.groupIcon}
      title={menuItem.groupTitle}
    >
      {menuItem.subMenu?.map((item) => {
        if (item.items?.length === 0) {
          return null;
        }

        return item.subMenu ? renderSubMenu(item, isSidebarItemVisible, getItemKey)
          : isSidebarItemVisible
            && item.groupKey
            && item.items
            && item.items.every((item) => isSidebarItemVisible(item)) ? (
              <Menu.SubMenu key={item.groupKey} title={item.groupTitle} icon={item.groupIcon}>
                {renderSubMenu(item, isSidebarItemVisible, getItemKey)}
                {item.items.map(
                  (subItem, i) =>
                    isSidebarItemVisible
                  && isSidebarItemVisible(subItem) && (
                      <Menu.Item
                        key={
                          subItem.key
                        || (getItemKey && getItemKey(subItem))
                        || subItem.href + i + subItem.text
                        }
                        icon={subItem.icon}
                        className={subItem.className}
                      >
                        <Link to={subItem.href}>{subItem.text}</Link>
                      </Menu.Item>
                    )
                )}
              </Menu.SubMenu>
            ) : (
              item.items
            && item.items.map(
              (subItem, i) =>
                isSidebarItemVisible
                && isSidebarItemVisible(subItem) && (
                  <Menu.Item
                    key={
                      subItem.key
                      || (getItemKey && getItemKey(subItem))
                      || subItem.href + i + subItem.text
                    }
                    icon={subItem.icon}
                    className={subItem.className}
                  >
                    <Link to={subItem.href}>{subItem.text}</Link>
                  </Menu.Item>
                )
            )
            );
      }
      )}
    </Menu.SubMenu>
  );
};

const HorizontalSidebar: FunctionComponent<SidebarProps> = ({
  loading = false,
  menuItems = [],
}) => {
  const { selectedKeys, isSidebarItemVisible, getItemKey } = useSidebar();

  return (
    <Menu
      mode={'horizontal'}
      theme="light"
      hidden={loading}
      selectedKeys={!!selectedKeys.length ? selectedKeys : ['index']}
      style={{
        background: 'hsl(0, 0%, 95%)',
        borderBottom: '1px solid hsl(0, 0%, 90%)',
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'space-around',
      }}
    >
      {menuItems.map((menuItem) => {
        if (
          menuItem.items?.every((item) => !isSidebarItemVisible(item))
          || menuItem.items?.length === 0
        ) {
          return null;
        }
        return !menuItem.subMenu || menuItem.subMenu.length === 0
          ? menuItem?.items?.map(
            (item) =>
              isSidebarItemVisible(item) && (
                <Menu.Item
                  key={item.key || getItemKey(item) || 'index'}
                  icon={item.icon}
                  className={`sidebar-item ${item.className}`}
                >
                  <Link to={item.href}>{item.text}</Link>
                </Menu.Item>
              )
          )
          : renderSubMenu(menuItem, isSidebarItemVisible, getItemKey);
      })}
    </Menu>
  );
};

const VerticalSidebar: FunctionComponent<SidebarProps> = ({
  loading = false,
  menuItems = [],
}) => {
  const {
    closedSidebarWidth,
    openedSidebarWidth,
    selectedKeys,
    isSidebarItemVisible,
    isSidebarCollapsed,
    getItemKey,
    toggleIsSidebarCollapsed,
  } = useSidebar();
  return (
    <div
      style={{
        background: 'hsl(0, 0%, 90%)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        width: isSidebarCollapsed ? closedSidebarWidth : openedSidebarWidth,
      }}
    >
      <Menu
        mode={'inline'}
        theme="light"
        hidden={loading}
        inlineCollapsed={isSidebarCollapsed}
        selectedKeys={!!selectedKeys.length ? selectedKeys : ['index']}
        style={{
          borderRight: 0,
          paddingTop: '32px',
          background: 'hsl(0, 0%, 90%)',
        }}
      >
        {menuItems.map((menuItem) => {
          if (
            menuItem.items?.every((item) => !isSidebarItemVisible(item))
            || menuItem.items?.length === 0
          ) {
            return null;
          }
          return !menuItem.subMenu || menuItem.subMenu.length === 0
            ? menuItem?.items?.map(
              (item) =>
                isSidebarItemVisible(item) && (
                  <Menu.Item
                    key={item.key || getItemKey(item) || 'index'}
                    icon={item.icon}
                    className={`sidebar-item ${item.className}`}
                  >
                    <Link to={item.href}>{item.text}</Link>
                  </Menu.Item>
                )
            )
            : renderSubMenu(menuItem, isSidebarItemVisible);
        })}
      </Menu>

      {!loading && (
        <div
          onClick={toggleIsSidebarCollapsed}
          className="sidebar-trigger"
          style={{ width: closedSidebarWidth }}
        >
          <Icon
            iconName={isSidebarCollapsed ? 'ChevronRight' : 'ChevronLeft'}
          />
        </div>
      )}
    </div>
  );
};

const MobileSidebar: FunctionComponent<SidebarProps> = ({
  loading = false,
  menuItems = [],
}) => {
  const { selectedKeys, isSidebarItemVisible, getItemKey } = useSidebar();
  return (
    <Stack>
      <Menu
        mode={'inline'}
        theme="light"
        hidden={loading}
        selectedKeys={!!selectedKeys.length ? selectedKeys : ['index']}
        style={{
          borderRight: 0,
          background: 'hsl(0, 0%, 100%)',
        }}
      >
        {menuItems.map((menuItem) => {
          if (
            menuItem.items?.every((item) => !isSidebarItemVisible(item))
            || menuItem.items?.length === 0
          ) {
            return null;
          }
          return !menuItem.subMenu || menuItem.subMenu.length === 0
            ? menuItem?.items?.map(
              (item) =>
                isSidebarItemVisible(item) && (
                  <Menu.Item
                    key={item.key || getItemKey(item) || 'index'}
                    icon={item.icon}
                    className={`sidebar-item ${item.className}`}
                  >
                    <Link to={item.href}>{item.text}</Link>
                  </Menu.Item>
                )
            )
            : renderSubMenu(menuItem, isSidebarItemVisible);
        })}
      </Menu>
    </Stack>
  );
};
