import {
  FunctionComponent,
  ReactElement,
  CSSProperties,
  PropsWithChildren,
  useEffect,
} from 'react';
import { mergeStyles } from '@fluentui/react';
import { useTheme } from 'styles';
import { LayoutContent, LayoutContentProps } from './LayoutContent';
import { LayoutHeader, LayoutHeaderProps } from './LayoutHeader';
import { LayoutLoading, LayoutLoadingProps } from './LayoutLoading';
import { LayoutSidebar, LayoutSidebarProps } from './LayoutSidebar';
import { AppVersion, Environment, useCompany } from 'integrations/crossborderit';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ImportWoony } from 'components/workspace/useWoony';

export interface LayoutProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
  mobileSidebarOpen?: boolean;
}

interface Props {
  appVersion?: AppVersion;
}

const selectUserMenuProps = (state: AppState): Props => ({
  appVersion: state.appVersion,
});
export interface LayoutComponent {
  (props: PropsWithChildren<LayoutProps>): ReactElement;
  Header: FunctionComponent<LayoutHeaderProps>;
  Sidebar: FunctionComponent<LayoutSidebarProps>;
  Content: FunctionComponent<LayoutContentProps>;
  Loading: FunctionComponent<LayoutLoadingProps>;
}

export const Layout: LayoutComponent = ({
  as: Element = 'div',
  className = '',
  mobileSidebarOpen = false,
  ...props
}) => {
  const { companyId, customerCompanyId } = useParams<{ companyId: string, customerCompanyId: string }>();
  const currentCompanyId = customerCompanyId || companyId;
  const { company } = useCompany(currentCompanyId);
  const { appVersion } = useSelector(selectUserMenuProps);
  const environmentName = appVersion?.environment || '';
  const companyName = company?.name || '';

  ImportWoony();

  useEffect(() => {
    if (appVersion?.environment !== Environment.Production && appVersion?.environment && companyName) {
      document.title = `${environmentName} (${companyName})`;
    } else if (appVersion?.environment !== Environment.Production && appVersion?.environment && !companyName) {
      document.title = `${environmentName}`;
    } else if (!!companyName) {
      document.title = companyName;
    } else {
      document.title = 'Crossborderit';
    }

  }, [environmentName, companyName, appVersion]);

  const theme = useTheme();

  const cbitLayoutStyles = mergeStyles({
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto 1fr',
    gridTemplateAreas: `
      'header'
      'sidebar'
      'content'
    `,
    [`@media(min-width: ${theme.breakpoints.tablet + 1}px) `]: {
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
        'header header'
        'sidebar content'
      `,
    },
    [`@media(max-width: ${theme.breakpoints.mobile}px)`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: mobileSidebarOpen ? 'auto auto 1fr' : 'auto 0 1fr',
      gridTemplateAreas: `
        'header'
        'sidebar'
        'content'
      `,
    },
  });

  return <Element className={`${cbitLayoutStyles} ${className}`} {...props} />;
};

Layout.Header = LayoutHeader;
Layout.Content = LayoutContent;
Layout.Sidebar = LayoutSidebar;
Layout.Loading = LayoutLoading;
